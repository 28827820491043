<template>
  <article-wrapper heading="Dan Chitwood">
    <!-- NOTE: content pulled from old site just to deploy -->
    <p>Dan Chitwood has acquired more than {{yearsExperience}} years of experience:</p>
    <ul>
      <li>
        13 years as a project manager for architecture, planning, engineering, and surveying firms.
      </li>
      <li>
        {{yearsExperience - 13}} years as the principal of his own firm.
      </li>
    </ul>

    <p>This experience gives him unique qualifications in landscape architecture.</p>
    <p>
      In addition to his specialty, residential landscaping and master planning,
      Dan Chitwood continues to provide consulting services for architects and engineers.
    </p>

    <p>Dan Chitwood's life is filled with art.</p>
    <ul>
      <li>He paints watercolors.</li>
      <li>He carves wood sculptures.</li>
      <li>He plays music and has released two CDs of original songs.</li>
    </ul>

    <p>Dan Chitwood's credentials include:</p>
    <ul>
      <li>Bachelor of Landscape Architecture from Virginia Tech in 1978</li>
      <li>Certification by the Uniform National Exam for Landscape Architects in 1983</li>
      <li>
        Current license with the Board for Architects, Professional Engineers,
        Land Surveyors, Interior Designers and Landscape Architects #0406-000194
      </li>
      <li>Adjunct faculty at Virginia Western Community College from 1994 to 2003</li>
      <li>Member of the Southwest Virginia Nursery &amp; Landscape Association</li>
      <li>Member of the Roanoke Regional Homebuilders Association</li>
    </ul>
  </article-wrapper>
</template>

<script>
export default {
  setup() {
    const firstYear = 1979;
    const currentYear = new Date().getFullYear();
    const yearsExperience = currentYear - firstYear;

    return { yearsExperience };
  },
};
</script>
